/**
 *
 * Build Tools for Building DOM Elements
 *
 */

utNavBuild = {};

/**
 *
 * Helper method to do a replace all in a string using the provided regex
 *
 * @param {regex} regex The pattern to look for to replace
 * @param {String} replaceWith The string to put in the place of matching items
 * @param {String} str The string the regex pattern will search in
 * @returns {unresolved}
 */

utNavBuild._replaceAll = function(regex, replaceWith, str) {
	return str.replace(new RegExp(regex, 'g'), replaceWith);
};

/**
 *
 * Builds a element node with no attributes and some display text
 *
 * @param {String} elementType
 * @param {String} displayValue
 * @returns {Element}
 */
utNavBuild.elementWithText = function (elementType, displayValue) {
	var element = document.createElement(elementType);
	element.appendChild(document.createTextNode(displayValue));
	return element;
};

/**
 *
 * Generic function that will build an element with optional displayText
 * and add attributes to the element based on the map using the map.key
 * as the attribute name and the map.value as the attributes value
 *
 * @param {string} elementToMake
 * @param {Object} elementAttributes
 * @param {string} displayText
 * @returns {Element}
 * example: utNavBuild.elementWithAttributes('div', {class: "foo", id: "faa"}, 'bar');
 */
utNavBuild.elementWithAttributes = function(elementToMake, elementAttributes, displayText) {
	var element = document.createElement(elementToMake);

	for (var prop in elementAttributes) {
		element.setAttribute(prop, elementAttributes[prop]);
	}

	if (displayText || displayText === 0) {
		element.appendChild(document.createTextNode(displayText));
	}
	return element;
};

/**
 *
 * Helps build a link will preventing the text javascript being in the
 * url.  One additional step against XSS.
 *
 * @param {String} link
 * @param {String} displayText Optional text to use for display. If not provided the link will be used as the display text value.
 * @returns {Element}]
 */
utNavBuild.anchor = function (link, displayText, wrapper) {
	link = utNavBuild._replaceAll('javascript', '', link);
	if (!displayText && !wrapper) {
		displayText = link;
	}
	return utNavBuild.elementWithAttributes('a', {href: utNavBuild._replaceAll('javascript', '', link)}, displayText);
};

/**
 *
 *
 * @param {svgPaths} array of path objects
 * @param {svgWidth} width
 * @param {svgHeight} height
 * @returns {svg element}
 */

utNavBuild.createSvg = function(svgPaths, svgWidth, svgHeight, svgClass) {
	
	if (!svgWidth) svgWidth = 48;
	if (!svgHeight) svgHeight = 48;
	svgViewBox = '0 0 ' + svgWidth + ' ' + svgHeight;
	svgWidth = svgWidth + 'px';
	svgHeight = svgHeight + 'px';

	var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		svg.setAttribute('viewBox', svgViewBox);
		svg.setAttribute('x', '0px');
		svg.setAttribute('y', '0px');
		svg.setAttribute('width', svgWidth);
		svg.setAttribute('height', svgHeight);
		svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
		if (svgClass) svg.setAttribute('class', svgClass);

	if (svgPaths) {
		for (var i = 0; i < svgPaths.length; i++) {
			var path = document.createElementNS('http://www.w3.org/2000/svg' ,'path');
				path.setAttributeNS(null , 'd', svgPaths[i].d);
				path.setAttributeNS(null , 'fill', svgPaths[i].fill);
			svg.appendChild(path);
		}
		return svg;
	}
};

/**
 *
 * ready fires callback when the document readyState is 'complete'
 * 
 * @callback {function} callback
 *
 * Reference:
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
 */

utNavBuild.ready = function(callback) {
	document.addEventListener('readystatechange', function(event){
		if (event.target.readyState === 'complete') {
			callback();
		}
	});
};
/**
 * HEADER INIT
 */

var utHeader = {};

// Get Head Element
utHeader.head = document.getElementsByTagName('head')[0];
// Get Body Element
utHeader.body = document.getElementsByTagName('body')[0];

// #############################################################
//	Setup URL for Local/test/stage/www.prod/secure.prod
//	returns nav/ location
// #############################################################

utHeader.url = function() {
	switch (window.location.host) {
		case 'localhost':
		case 'localhost.utah.gov':
		case 'prototype.utah.gov':
		case 'secure.office.uii':
		case 'stage.utah.gov':
		case 'secure.utah.gov':
		case 'www.utah.gov':
			return '/nav/';
			break;
		default:
			console.log('https://www.utah.gov/nav/ is css source');
			return 'https://www.utah.gov/nav/';
	}
};

// Get JS Element
utHeader.possible = 'script[src*="navheader.js"], script[src*="fluidheader.js"], script[src*="650header.js"], script[src*="header.js"]';
utHeader.header = document.querySelectorAll(utHeader.possible)[0];
utHeader.source = utHeader.header.src;

// Set Class for header src type
if (utHeader.source.indexOf('navheader.js') != -1 || utHeader.source.indexOf('fluidheader.js') != -1) {
	utHeader.class = 'UTfluid'
}
else if (utHeader.source.indexOf('650header.js') != -1) {
	utHeader.class = 'UT650'
}
else if (utHeader.source.indexOf('header.js') != -1) {
	utHeader.class = 'UT755'
}

// Setup Links
utHeader.links = [
	{title: 'Services', alt: 'Utah.gov Services', href: 'https://www.utah.gov/services/', target: '_blank'},
	{title: 'Agencies', alt: 'Utah.gov Agency Listings', href: 'https://www.utah.gov/government/agencylist.html', target: '_blank'}
]
// UTAH.GOV SVG LOGO
utHeader.logo = {
	paths: [
		{fill: '#E6B42A', d: 'M0 35.5c0-4.8 3.9-8.7 8.7-8.7 4.8 0 8.7 3.9 8.7 8.7 0 4.8-3.9 8.7-8.7 8.7S0 40.3 0 35.5z'},
		{fill: '#FFFFFF', d: 'M3 12v10.9c0 2.6 1.1 3.9 3.4 3.9 1 0 1.9-0.3 2.7-0.9 0.8-0.6 1.4-1.2 1.6-2v-12h3v17h-3v-2.4c-0.3 0.7-1 1.3-2 1.8 -1 0.6-2 0.8-3 0.8 -1.9 0-3.3-0.5-4.3-1.6S0 24.9 0 22.9V11.7H3L3 12 3 12zM17.2 14.4h-2V12h2V8.4l3-1.2v4.7h4.7v2.4h-4.7v8.5c0 1.4 0.2 2.4 0.7 3.1 0.5 0.6 1.3 0.9 2.3 0.9 0.8 0 1.6-0.2 2.4-0.6l0.4 2.7c-1.3 0.3-2.6 0.5-4.1 0.5 -1.3 0-2.5-0.5-3.4-1.5 -0.9-1-1.4-2.3-1.4-3.8L17.2 14.4 17.2 14.4zM36.8 27.1c-1.2 1.5-3.1 2.3-5.6 2.3 -1.3 0-2.5-0.5-3.5-1.5 -1-1-1.5-2.2-1.5-3.6 0-1.7 0.8-3.2 2.3-4.4 1.5-1.2 3.4-1.8 5.8-1.8 0.6 0 1.4 0.1 2.2 0.4 0-2.8-1.2-4.1-3.7-4.1 -1.9 0-3.3 0.5-4.4 1.5l-1.3-2.5c0.6-0.5 1.4-0.9 2.4-1.2 1-0.3 2-0.5 2.9-0.5 2.5 0 4.3 0.6 5.4 1.7 1.1 1.1 1.7 2.9 1.7 5.4v6.1c0 1.5 0.4 2.5 1.3 3v1.5c-1.2 0-2.2-0.2-2.8-0.5C37.5 28.5 37.1 27.9 36.8 27.1zM36.5 20.7c-1-0.2-1.6-0.3-2-0.3 -1.5 0-2.8 0.4-3.7 1.2 -1 0.8-1.4 1.7-1.4 2.8 0 1.8 1 2.7 3.1 2.7 1.5 0 2.9-0.7 4.1-2.2L36.5 20.7 36.5 20.7zM53 29.1V18.4c0-1.3-0.3-2.3-0.9-3 -0.6-0.7-1.5-1.1-2.6-1.1 -0.7 0-1.5 0.2-2.2 0.6 -0.7 0.4-1.3 0.9-1.7 1.5v12.7h-3v-23l3-1V14c0.4-0.6 1.1-1.1 1.9-1.6 0.9-0.4 1.8-0.6 2.8-0.6 1.8 0 3.2 0.6 4.2 1.8 1 1.2 1.5 2.8 1.5 4.9v10.7L53 29.1 53 29.1z'},
		{fill: '#FFFFFF', d: 'M16.9 49l1.4-2.1c1.5 1 3 1.5 4.3 1.5 1.2 0 2.1-0.2 2.8-0.6s1-0.9 1-1.5c0-1.2-0.9-1.8-2.6-1.8 -0.3 0-0.8 0.1-1.6 0.2 -0.8 0.1-1.4 0.2-1.8 0.2 -2.1 0-3.2-0.8-3.2-2.4 0-0.5 0.2-0.9 0.7-1.3 0.5-0.4 1.1-0.7 1.8-0.9 -2.1-1-3.1-2.7-3.1-5.1 0-1.6 0.5-2.9 1.6-3.9 1.1-1 2.4-1.6 4-1.6 1.5 0 2.6 0.3 3.4 0.9l1.3-1.6 1.7 1.6L27 31.8c0.7 0.9 1 2 1 3.5 0 1.5-0.5 2.8-1.4 3.8 -0.9 1-2.2 1.6-3.7 1.8l-2.2 0.2c-0.3 0-0.6 0.1-1 0.3 -0.4 0.2-0.7 0.4-0.7 0.7 0 0.4 0.5 0.6 1.4 0.6 0.4 0 1-0.1 1.9-0.2 0.8-0.1 1.5-0.2 1.9-0.2 1.5 0 2.6 0.4 3.4 1.1 0.8 0.7 1.2 1.7 1.2 2.9 0 1.4-0.6 2.5-1.8 3.3 -1.2 0.8-2.8 1.3-4.7 1.3 -1 0-2-0.2-3-0.5C18.4 49.9 17.6 49.5 16.9 49zM22.5 31.8c-0.9 0-1.7 0.3-2.2 1 -0.6 0.6-0.9 1.4-0.9 2.4 0 1 0.3 1.9 0.8 2.6 0.6 0.7 1.3 1 2.3 1 0.9 0 1.7-0.3 2.2-1 0.5-0.7 0.8-1.5 0.8-2.6 0-0.9-0.3-1.7-0.9-2.4C24.1 32.2 23.4 31.8 22.5 31.8zM28.8 37.4c0-2.3 0.6-4.2 1.9-5.6 1.2-1.4 2.9-2.1 4.9-2.1 2.1 0 3.8 0.7 5 2 1.2 1.4 1.8 3.2 1.8 5.7 0 2.4-0.6 4.3-1.8 5.7 -1.2 1.4-2.8 2.1-4.9 2.1 -2.1 0-3.8-0.7-5-2.1C29.4 41.7 28.8 39.8 28.8 37.4zM31.5 37.4c0 3.7 1.3 5.6 4 5.6 1.2 0 2.2-0.5 2.9-1.5s1.1-2.4 1.1-4.1c0-3.7-1.3-5.5-4-5.5 -1.2 0-2.2 0.5-2.9 1.5C31.9 34.3 31.5 35.7 31.5 37.4zM49.5 45.2h-0.7L42.4 30h2.9l3.9 10.4 4-10.4H56L49.5 45.2z'}
	]
};

utHeader.logo.svg = utNavBuild.createSvg(utHeader.logo.paths, 56, 56);

// SVG ICONS
utHeader.icons = {
	searchPaths: [
		{fill: '#444', d: 'M18.5 17h-0.8l-0.3-0.3c1-1.1 1.6-2.6 1.6-4.2C19 8.9 16.1 6 12.5 6S6 8.9 6 12.5 8.9 19 12.5 19c1.6 0 3.1-0.6 4.2-1.6l0.3 0.3v0.8l5 5 1.5-1.5L18.5 17zM12.5 17C10 17 8 15 8 12.5S10 8 12.5 8s4.5 2 4.5 4.5S15 17 12.5 17z'}
	],
	closePaths: [
		{fill: '#444', d: 'M23,10.4L21.6,9L16,14.6L10.4,9L9,10.4l5.6,5.6L9,21.6l1.4,1.4l5.6-5.6l5.6,5.6l1.4-1.4L17.4,16L23,10.4z'}
	]
}
utHeader.icons.search = utNavBuild.createSvg(utHeader.icons.searchPaths, 32, 32, 'utSearchIcon');
utHeader.icons.close = utNavBuild.createSvg(utHeader.icons.closePaths, 32, 32, 'utCloseIcon utNavDisabled');



utHeader.search = {};

utHeader.search.createElements = function() {
	utHeader.search.container = utNavBuild.elementWithAttributes('div', {
			class: 'utahgov_header_search',
			id: 'utahgov_header_search'
		});

	utHeader.search.button = utNavBuild.elementWithAttributes('button', {
			class: 'utahgov_header_search_button',
			id: 'utahgov_header_search_button',
			type: 'button'
		}, 'Search Utah.gov');

	utHeader.search.input = utNavBuild.elementWithAttributes('input', {
			class: 'utahgov_search utNavDisabled',
			id: 'utahgov_search',
			type: 'text',
			placeholder: 'Utah.gov Search...'
		});
}();

utHeader.search.activate = function(e) {
	e.preventDefault();
	utHeader.search.input.classList.remove('utNavDisabled');
	utHeader.search.input.focus();
	utHeader.search.button.classList.add('utNavDisabled');
	utHeader.icons.search.classList.add('utNavDisabled');
	utHeader.icons.close.classList.remove('utNavDisabled');
};

utHeader.search.deactivate = function() {
	utHeader.search.input.classList.add('utNavDisabled');
	utHeader.search.button.classList.remove('utNavDisabled');
	utHeader.icons.search.classList.remove('utNavDisabled');
	utHeader.icons.close.classList.add('utNavDisabled');
};

utHeader.search.send = function(e) {
	if (e.keyCode === 27) {
		utHeader.search.deactivate();
	}
	else if (e.keyCode === 13 && e.target.value.length > 0) {
		var param = utNavBuild._replaceAll('javascript', '', e.target.value);
			param = utNavBuild._replaceAll(' ', '+', param);
		window.location.href = 'https://www.utah.gov/search-results.html?q=' + param;
	}
};

utHeader.search.addListeners = function() {
	utHeader.search.button.addEventListener('click', utHeader.search.activate, false);
	utHeader.icons.search.addEventListener('click', utHeader.search.activate, false);
	utHeader.icons.close.addEventListener('click', utHeader.search.deactivate, false);
	utHeader.search.input.addEventListener('keypress', utHeader.search.send, false);
}();

utHeader.search.output = function() {
	utHeader.search.container.appendChild(utHeader.search.button);
	utHeader.search.container.appendChild(utHeader.search.input);
	utHeader.search.container.appendChild(utHeader.icons.search);
	utHeader.search.container.appendChild(utHeader.icons.close);
}();



utHeader.addStyles = function() {
	// Add link to header <link rel="stylesheet" href="nav.css">
	utHeader.cssUrl = utHeader.url() + 'nav.css';
	utHeader.csslink = utNavBuild.elementWithAttributes('link', {
			rel: 'stylesheet',
			href: utHeader.cssUrl
		}, null);
	// CSS
	utHeader.head.appendChild(utHeader.csslink);	
}();

utHeader.buildContainer = function() {
	// Create Nav Container
	utHeader.container = utNavBuild.elementWithAttributes('div', {
			class: 'statewideheader',
			id: 'utahgov_statewideheader'
		}, null);

	if (utHeader.header.dataset.theme) {
		utHeader.container.dataset.theme = utHeader.header.dataset.theme;
	}
	if (utHeader.header.dataset.style) {
		utHeader.container.dataset.style = utHeader.header.dataset.style;
	}

	// Create Logo Container in Nav
	utHeader.logoAnchor = utNavBuild.elementWithAttributes('a', {
			'href': 'https://www.utah.gov',
			'title': 'Utah.gov',
			'alt': 'Utah.gov',
			'class': 'utahgov_statewideheader_brand',
			'id': 'utahgov_statewideheader_brand'
		}, null);

	// utHeader.logoAnchor.innerHTML = utHeader.logo;
	utHeader.logoAnchor.appendChild(utHeader.logo.svg);
	utHeader.container.appendChild(utHeader.logoAnchor);
	// Add Search Container to NavContainer
	utHeader.container.appendChild(utHeader.search.container);
}();

utHeader.createLinks = function() {
	// Create Links UL
	utHeader.ul = document.createElement('ul');
	// Create Links in LI
	for (var i = 0; i < utHeader.links.length; i++) {
		utHeader.addLi = document.createElement('li');
		utHeader.addAnchor = utNavBuild.elementWithAttributes('a', utHeader.links[i], utHeader.links[i].title);
		utHeader.addLi.appendChild(utHeader.addAnchor);
		utHeader.ul.appendChild(utHeader.addLi);
	}
	// Add Elements to Nav Container
	utHeader.container.appendChild(utHeader.ul);
}();


// Check to see if Tag has a ParentNode of if parent is BODY
utHeader.hasParent = function() {
	return (utHeader.headerJS().parentNode.tagName === 'BODY');
};

// utHeader.headerJS()
// Add Nav Container to Body where Script is located,
// or first child of body
// Also Determine Class for Nav Container Based on JS file

// Check wich Element is used
utHeader.headerJS = function() {
	utHeader.container.classList.add(utHeader.class);
	return utHeader.header;
};

// Output Container to proper location

utNavBuild.ready(function(){
	// Parent Check
	console.log(utHeader.headerJS());
	if (utHeader.hasParent()) {
		utHeader.body.insertBefore(utHeader.container, utHeader.headerJS());
	} else {
		utHeader.headerJS().parentNode.insertBefore(utHeader.container, utHeader.headerJS());
	}
});
